<template>
  <section>
    <div class="content-header">
      <h2>Works cited </h2>
    </div>
    <div class="content-wrapper">
<p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared. </p>
      <ul>
        <li class="pb-3"><em>Teaching + Learning Resources | wellbeing.ubc.ca.</em> (n.d.). UBC Wellbeing. <br><a href="https://wellbeing.ubc.ca/tools-and-resources/teaching-learning-resources" target="_blank">wellbeing.ubc.ca/tools-and-resources/teaching-learning-resources</a></li>
        <li class="pb-3">Ezekiel, F. (2021). Supporting Mental Health and Learning among Students with Marginalized Identities. <em>Centennial College Student Success Summit</em>.</li>
        <li class="pb-3"><em>“Positionality & Research: How our Identities Shape Inquiry.”</em> (2021, March 16). [Video]. YouTube. <a href="https://www.youtube.com/watch?v=fTHFud7fr8c" target="_blank">www.youtube.com/watch?v=fTHFud7fr8c</a></li>
        <li class="pb-3">Holmes, Andrew Gary Darwin. “Researcher Positionality - A Consideration of Its Influence and Place in Qualitative Research - A New Researcher Guide.” Shanlax International Journal of Education, vol. 8, no. 4, 2020, pp. 1-10.</li>
        <li class="pb-3">Ezekiel, F. (2021). Supporting Mental Health and Learning among Students with Marginalized Identities. <em>Centennial College Student Success Summit</em>. <a href="" target="_blank"></a></li>
        <li class="pb-3">Deirdre Pike, Creating and Supporting LGBTQ Positive Space Groups in the Hamilton Wentworth-District School Board – A Resource Guide for Secondary School Administrators, Teachers, and Support Staff (2010), 8.<a href="" target="_blank"></a></li>
        <li class="pb-3">Centre for Applied Special Technology (CAST). Universal Design for Learning Framework. Available Online:  <a href="https://www.cast.org/impact/universal-design-for-learning-udl" target="_blank">www.cast.org/impact/universal-design-for-learning-udl</a></li>
        <li class="pb-3">Meyer, A., Rose, D. H., & Gordon, D. T. (2014). <em>Universal design for learning: Theory and practice</em>.</li>

      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
